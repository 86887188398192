<template>
  <div class="agrement-policy-container">
    <div>
      <div
        class="Section0"
        style="layout-grid:15.6000pt;"
      >
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">尊敬的用户，您好！</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;text-indent:21.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">在您正式进行注销流程前，我们郑重提示您，</font>
          </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">注销帐号后，您将无法再以该帐号登录和使用所有适用该帐号的产品及服务（包括但不限于</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Meta彼岸的各项</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">产品和服务），也无法找回您通过该帐号添加或绑定的任何内容和信息。</font>
            </span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">为了避免给您带来不便，请您在注销帐号之前慎重考虑，如您经慎重考虑后仍决定注销帐号的，请您务必仔细阅读并充分理解本协议的全部内容。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">一、您根据自主意愿选择注销平台账户。您已充分阅读、理解本协议的全部内容，并做出以下承诺</font>:
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">1.本人承诺，本人持有的&#8220;Meta彼岸"平台账号:</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>1）帐号未被平台判定为风险账户，亦不在处罚状态中，且能正常登录;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>2）账号内订单状态均已完成，无纠纷订单或未处理订单;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>3）账号的数字艺术品交易中，不存在潜在纠纷。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">2.本人承诺，本人有权决定该帐号的注销事宜，不侵犯任何第三方的合法权益，如因此引发任何争议，由本人自行承担。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">二、注销应具备的条件及时间规则</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">1、应具备条件：</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>1）账号的状态正常，无任何投诉，违规等记录;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>2）账户内所有订单（包括但不限于购买订单、转赠订单、合成订单、盲盒订单）的状态都已完成;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span class="msoIns"><ins
                cite="mailto:刚刚"
                datetime="2022-07-11T16:52"
              >

              </ins></span></span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>3）账户内的所有数字藏品都已清空;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span class="msoIns"><ins
                cite="mailto:刚刚"
                datetime="2022-07-11T16:52"
              >

              </ins></span></span></p>

        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">2、时间规则：</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">（</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">1）申请注销之日起7天内</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">（含）为账号保护期，若您重新登录账户，则账号恢复正常，视为您撤销注销账号申请。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">（</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">2）</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">申请注销之日起</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">7天以上，30</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">天内（含）为账户锁定期，锁定期账户不可再登录系统。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">（</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">3）</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">申请注销之日起</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">30天后，</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">系统将账户进行注销操作，将删除账户相关的信息，包括不限于头像、手机号、实名信息、数字艺术品等；</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">（</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">4</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">）申请注销之日起</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">180</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">天内（含），注销过的手机号不可在</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">Meta彼岸系统中</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">再次注册成为系统用户。</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">（</font>5）申请注销之日起1
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">80</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
              <font face="宋体">天后，如您用相同手机号再次向我们申请注册</font>Meta彼岸的，此时注册申请账号视为新的用户账号。因此，我们善意的提醒您在申请注销前对需要备份的内容提前做好备份。
            </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

            </span></b></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">三、您已经仔细阅读与充分理解、且同意本协议的全部内容；同时您已经自行检查并确认满足本协议第二条项下注销所需具备的全部条件。</font>
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">1、您理解并同意，我们对您的帐号是否符合注销条件可能仅做初步检测和判断，部分条件依赖于您的自行检查和确认；您确保已经自行检查并确认满足本协议第二条项下注销所需具备的全部条件，并承诺接受本协议提示的由此（可能）带来的所有后果。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">2、您理解并同意，帐号注销后，&#8220;Meta彼岸&#8221;平台及其经营者将无法协助本人重新恢复该账号。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">3、帐号注销后，您平台账号中已绑定的手机号将会被解除绑定。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">4、注销帐号后，您将无法再使用本帐号，也将无法找回您帐号中及与帐号相关的任何内容或信息，包括但不限于:&#160;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>1）您将无法继续使用该帐号进行登录;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>2）您帐号的个人资料和历史信息(包含昵称、头像、订单记录等)都将无法找回;&#160;
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>3）您理解并同意，注销帐号后，您曾获得的数字藏品、空投藏品及其他虚拟财产等将视为您自愿、主动放弃，无法继续使用，由此引起一切纠纷由您自行处理并独立承担相应责任。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">（</font>4）您理解并同意，非法定应保留和内容的信息外，本帐号内的所有内容、信息、记录均会被删除或匿名化，且无法恢复。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">5、为了进一步保障您的合法权益，我们有权（并非义务）根据您的账号安全状态及您的相关产品和服务使用情况等综合判断您的账号是否符合注销条件。为了保障您的账号安全和其他合法权益，我们有权进行注销申请的身份验证以判断您是该账号的有权使用人（包括但不限于手机验证等）。如我们在综合判断后发现该账号不符合注销条件的或身份验证失败的，您的注销可能会失败，您可以按照我们的指引进行后续操作或联系客服协助处理。即使我们判断您符合注销条件的，也是基于您的承诺进行的形式审查，仍由您自身对账号符合上述注销条件承担保证责任，我们对此不承担相应的法律责任。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">6</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">、在帐号注销期间，如果您的帐号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，</font>&#8220;Meta彼岸"及其运营者有权自行中止或终止您的帐号注销程序，而无需另行得到您的同意。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">7</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">、您理解并同意，平台帐号一旦注销（正式注销），您与我们签署的《</font>Meta彼岸用户服务协议》（&#8220;用户协议&#8221;）终止（已约定继续生效的除外）。用户协议的终止并不影响账户注销前您使用本服务应当承担的相关责任，您仍需对账户注销前使用本服务的行为承担相应责任，包括但不限于可能产生的违约责任、损害赔偿责任等。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span class="msoIns"><ins
                cite="mailto:Janice Stephen"
                datetime="2022-07-08T14:00"
              >

              </ins></span></span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">8</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">、本账号一旦注销，</font>&#8220;Meta彼岸&#8221;及其运营者有义务根据《电子商务法》《网络安全法》《互联网信息服务管理办法》《互联网文化管理暂行规定》等法律法规的规定以存储期限最小化的原则处理法定应保存的信息，且在超出法定期限后对相关信息的删除或匿名化处理无须再次征得您同意。上述个人信息包括但不限于：头像、图片、购买记录等内容。
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">修改时间：</font>2022年7月6日
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
        <p
          class=MsoNormal
          style="margin-bottom:12.0000pt;line-height:22.0000pt;mso-line-height-rule:exactly;"
        ><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">
            <font face="宋体">生效时间：</font>2022年7月10日
          </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;mso-font-kerning:1.0000pt;">

          </span></p>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
    this.$store.commit('HIDE_APPLOADING')
  },
}
</script>